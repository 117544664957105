import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, TextInput, Button } from 'grommet';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Checkmark } from 'grommet-icons';

const CopyableInput = ({ value, displayValue }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  return (
    <Box direction="row">
      <TextInput
        value={displayValue || value}
        readonly
        pad={{ vertical: '30px', horizontal: '30px' }}
        style={{
          height: '100%',
          padding: '20px 30px',
          fontWeight: 500,
          fontSize: '0.93333rem',
          letterSpacing: '1px',
        }}
      />
      <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
        <Button
          primary
          label={copied ? null : 'Copy'}
          icon={copied ? <Checkmark size="17px" /> : null}
          style={{ padding: '20px 30px', fontSize: '0.93333rem' }}
        />
      </CopyToClipboard>
    </Box>
  );
};

CopyableInput.propTypes = {
  value: PropTypes.string,
  displayValue: PropTypes.string,
};

export default CopyableInput;
