import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Text } from 'grommet';

import CopyableInput from '../CopyableInput';
import selectReferralUrl from '../../state/industry/selectReferralUrl';

const MemberReferralLink = ({ layout }) => {
  const referralUrl = useSelector(selectReferralUrl);
  if (!referralUrl) return null;
  switch (layout) {
    case 'inline':
      return <Text>{referralUrl}</Text>;
    case 'copyable':
      return <CopyableInput value={referralUrl} displayValue={referralUrl} />;
    default:
      return <Text>{referralUrl}</Text>;
  }
};

MemberReferralLink.propTypes = {
  layout: PropTypes.oneOf(['inline', 'copyable']),
};

export default memo(MemberReferralLink);
